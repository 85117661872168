import React from 'react';
import Typography from 'components/ui-components-v2/Typography';

import '../styles/info.scss';

interface Props {
    title: string;
    subtitle: string;
    small?: boolean;
}

const MediaManagementAssetTileInfo = ({ title, subtitle, small }: Props) => {
    return (
        <div className="media-management-asset-tile-info">
            <Typography variant={small ? 'h5' : 'h4'} data-cy="mediaManagement-assetTitle-typograpy">
                {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" noWrap>
                {subtitle}
            </Typography>
        </div>
    );
};

export default MediaManagementAssetTileInfo;
