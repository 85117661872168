import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import ToolIconButton from 'components/ui-components/ToolIconButton';
import { useAMData, useAMNavigation } from 'components/asset-management/hooks/';
import { canOrganise } from 'components/asset-management/utilities';
import AssetManagementActionsWrapper from 'components/asset-management/components/actions/actions-wrapper';
import useAMAssetSelection from 'components/asset-management/hooks/useAMAssetSelection';
import AMCheckbox from 'components/asset-management/components/am-checkbox';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import Translation from 'components/data/Translation';
import Tooltip from 'components/ui-components-v2/Tooltip';
import { MediaAsset } from '../../../types/media-management.type';
import MediaManagementAssetMenu from '../../asset-menu';
import MediaManagementAssetTilePreview from './preview';
import MediaManagementAssetTileInfo from './info';

import '../styles/main.scss';

interface Props {
    asset: MediaAsset;
}

const MediaManagementAssetTile = ({ asset }: Props) => {
    const { openAssetDetailOverlay, rootPath } = useAMNavigation();
    const { userHasRight } = useAssetManagementConfigContext();
    const {
        data: { selectedAssetIds }
    } = useAMData<{ selectedAssetIds: string[] }>({
        selectedAssetIds: 'state.selectedAssetIds'
    });
    const [hovered, setHovered] = useState(false);
    const [keepMenuMounted, setKeepMenuMounted] = useState(false);
    const canOrg = useMemo(() => canOrganise(userHasRight, asset.status), [asset.status]);
    const tileRef = useRef<HTMLDivElement>(null);

    const { isSelected, selectAsset } = useAMAssetSelection(asset._id);

    // We should the menu mounted when it's open or a dialog is opened from the menu, even if the mouse leaves the tile.
    const handleKeepMenuMounted = (open: boolean) => {
        setKeepMenuMounted(open);

        // When we close the menu, we want to set the hovered state to false because onMouseLeave isn't called in that scenario.
        if (!open) {
            if (!tileRef.current?.matches(':hover')) {
                setHovered(false);
            }
        }
    };

    const onTileClick = () => {
        if (selectedAssetIds.length) {
            selectAsset(asset._id);
            return;
        }

        openAssetDetailOverlay(asset._id);
    };

    return (
        <div
            id={`asset_${asset._id}`}
            data-tour-selector="am-asset"
            data-tour-next-url={`/${rootPath}/${asset._id}`}
            data-cy="mediaManagement-assetTile-div"
            data-type={asset.type}
            data-sub-type={asset.subType}
            className={classNames('media-management-asset-tile', 'asset-management-selectable-asset', {
                'media-management-asset-tile--selected': isSelected
            })}
            ref={tileRef}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={onTileClick}>
            <div className="media-management-asset-tile__preview">
                <MediaManagementAssetTilePreview asset={asset} />
                {(hovered || isSelected || keepMenuMounted) && (
                    <div
                        className={classNames('media-management-asset-tile__overlay', {
                            'media-management-asset-tile__overlay--selected': isSelected
                        })}>
                        <AMCheckbox asset={asset} className="media-management-asset-tile__checkbox" />
                        {(hovered || keepMenuMounted) && (
                            <React.Fragment>
                                <AssetManagementActionsWrapper className="media-management-asset-tile__tools">
                                    <Tooltip title={Translation.get('actions.openPreview', 'asset-management')}>
                                        <span>
                                            <ToolIconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openAssetDetailOverlay(asset._id);
                                                }}>
                                                <Icon>open_in_full</Icon>
                                            </ToolIconButton>
                                        </span>
                                    </Tooltip>
                                    {canOrg && (
                                        <Tooltip title={Translation.get('labels.organise', 'common')}>
                                            <span>
                                                <ToolIconButton
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openAssetDetailOverlay(asset._id, 'organise');
                                                    }}>
                                                    <Icon>bookmarks_outline</Icon>
                                                </ToolIconButton>
                                            </span>
                                        </Tooltip>
                                    )}
                                    <MediaManagementAssetMenu asset={asset} buttonType="toolIcon" onSetKeepMenuMounted={handleKeepMenuMounted} />
                                </AssetManagementActionsWrapper>
                                <div className="media-management-asset-tile__extension">{asset.data.files?.[0]?.extension}</div>
                            </React.Fragment>
                        )}
                    </div>
                )}
            </div>
            <MediaManagementAssetTileInfo
                title={asset.title}
                subtitle={`${Translation.get(`sub_types.${asset.subType}`, 'media-management')} | ${asset.data.files?.[0]?.humanSize}`}
            />
        </div>
    );
};

export default MediaManagementAssetTile;
